* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
  border: none;
  scroll-behavior: smooth;
  font-family: 'Poppins';
  scroll-behavior: smooth;
}

body {
  background: #f8f8f8 !important;
}

a {
  text-decoration: none;
}

a:visited {
  color: unset;
}

button {
  background: none;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Medium.woff') format('woff'),
    url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.eot');
  src: url('./assets/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Poppins-SemiBold.woff') format('woff'),
    url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.eot');
  src: url('./assets/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Poppins-Regular.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Regular.woff') format('woff'),
    url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./assets/fonts/AvenirNextLTPro-Regular.eot');
  src: url('./assets/fonts/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-Regular.woff') format('woff'),
    url('./assets/fonts/AvenirNextLTPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./assets/fonts/AvenirNextLTPro-Bold.eot');
  src: url('./assets/fonts/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
    url('./assets/fonts/AvenirNextLTPro-Bold.woff') format('woff'),
    url('./assets/fonts/AvenirNextLTPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DMSans-Regular.eot');
  src: url('./assets/fonts/DMSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/DMSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/DMSans-Regular.woff') format('woff'),
    url('./assets/fonts/DMSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

input:-webkit-autofill::first-line {
  font-size: 16px;
}

@font-face {
  font-family: 'VideoJS';
  src: url('https://vjs.zencdn.net/f/1/vjs.eot');
  src: url('https://vjs.zencdn.net/f/1/vjs.eot?#iefix') format('embedded-opentype'),
    url('https://vjs.zencdn.net/f/1/vjs.woff') format('woff'),
    url('https://vjs.zencdn.net/f/1/vjs.ttf') format('truetype');
}

.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
.vjs-icon-pause:before {
  content: '\f103';
  font-family: 'VideoJS';
}

.video-js .vjs-mute-control .vjs-icon-placeholder:before,
.vjs-icon-volume-high:before {
  content: '\f107';
  font-family: 'VideoJS';
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  content: '\f101';
  font-family: 'VideoJS';
}

.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before,
.vjs-icon-picture-in-picture-enter:before {
  content: '\f121';
  font-family: 'VideoJS';
}

.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.vjs-icon-fullscreen-enter:before {
  content: '\f108';
  font-family: 'VideoJS';
}
